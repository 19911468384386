<template>
  <span class="c-badge">
    <slot />
  </span>
</template>

<script setup lang="ts"></script>

<style lang="scss">
@use "@styles/components/badge";
</style>
